import React from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './Ads.css'

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Adcarousel = (props) => {
    // console.log('props.imgURL:', props.imgURLs);
    // console.log('props.imgURL length:', props.imgURLs.length);
    // console.log('props.width:', props.width);
    // console.log('props.height:', props.height);
    return (

        props.imgURLs && <div className='swipercomp'>
            <div className='swipercomp_inner'>
                <Swiper
                    navigation
                    pagination={{ clickable: true }}
                    spaceBetween={30}
                    slidesPerView={1}
                    autoplay={{ delay: 3000 }} // Autoplay configuration
                    loop // Enable looping behavior
                >
                    {
                        props.imgURLs.map(img => (
                            <SwiperSlide >
                                <div className='ad_container'>
                                    <img src={img} alt='' width={props.width} height={props.height} />
                                </div>
                            </SwiperSlide>
                        ))
                    }

                    {/* <SwiperSlide>
                        <div className='ad_container'>
                            <img src='./assets/img/ad7.png' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='ad_container'>
                            <img src='./assets/img/ad8.png' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='ad_container'>
                            <img src='./assets/img/ad9.png' />
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>
        </div>

    );
};

export default Adcarousel;
