import React from 'react'
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

const HowToUse = () => {
    // Scroll to the top of the page whenever the location changes start
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    // Scroll to the top of the page whenever the location changes end
    return (
        <div className='top_header_pg pa_bg'>
            <div className='pa_inner_page'>
                <div className="pg_header">
                    <h2 className="p_title">How to use PropAgent</h2>
                    <h4 className="p_subtitle">
                        Discover the features and functions of PropAgent
                    </h4>
                </div>
                <hr></hr>
                {/* <div className="verticall_gap"></div> */}
                <div className='blog_sect app_videos'>
                    <div className="card-container av_single">
                        <div className="card-image">
                            <video src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/videos%2Finstallapp.mp4?alt=media&token=0bda40a3-d254-4c92-97af-cfc0290fcadf" controls
                                poster="./assets/img/howinstall.png"></video>
                        </div>
                        <div className="card-body">
                            {/* <span className="card-badge">Business</span> */}
                            {/* <div className="blog_source">
                            <img src="./assets/img/blog_source1.webp" alt="" />
                        </div> */}
                            <h3>
                                How to install PropAgent App

                            </h3>
                            <p className="card-subtitle">
                                Upon visiting the website, you'll encounter a pop-up prompt encouraging you to install the PropAgent App
                            </p>
                            {/* <div className="card-author">
                            <img src="./assets/img/blog1.jpg" alt="author avatar" />
                            <div className="author-info">
                                <p className="author-name">John Doe</p>
                                        <p className="post-timestamp">2h ago</p>
                                <Link className="learn-more pointer" to="https://helloentrepreneurs.in/prop-tech-startup-propdial-forays-into-2nd-home-shared-ownership-space/">
                                    Read More
                                </Link>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="card-container av_single">
                        <div className="card-image">
                            <video src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/videos%2Flogin.mp4?alt=media&token=4178d4a4-e505-4519-bc8e-c5d84b0d1039" controls poster="./assets/thumbnails/login.png"></video>
                        </div>
                        <div className="card-body">
                            {/* <span className="card-badge">Business</span> */}
                            {/* <div className="blog_source">
                            <img src="./assets/img/blog_source1.webp" alt="" />
                        </div> */}
                            <h3>
                                How to login in PropAgent

                            </h3>
                            <p className="card-subtitle">
                                In the login section, input your mobile number, initiate the OTP request. This action prompts PropAgent to send a unique OTP to your mobile device via SMS
                            </p>
                            {/* <div className="card-author">
                            <img src="./assets/img/blog1.jpg" alt="author avatar" />
                            <div className="author-info">
                                <p className="author-name">John Doe</p>
                                        <p className="post-timestamp">2h ago</p>
                                <Link className="learn-more pointer" to="https://helloentrepreneurs.in/prop-tech-startup-propdial-forays-into-2nd-home-shared-ownership-space/">
                                    Read More
                                </Link>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="card-container av_single">
                        <div className="card-image">
                            <video src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/videos%2Faddproperty.mp4?alt=media&token=88f339ef-768f-4b29-aa49-d85e2e67e940" controls poster="./assets/img/postproperty.jpg"></video>
                        </div>
                        <div className="card-body">
                            {/* <span className="card-badge">Business</span> */}
                            {/* <div className="blog_source">
                            <img src="./assets/img/blog_source1.webp" alt="" />
                        </div> */}
                            <h3>
                                How to post your property

                            </h3>
                            <p className="card-subtitle">
                                On add property section, Fill in the required details about your property, including its location, type (e.g., apartment, house), size, amenities, and any additional features.
                            </p>
                            {/* <div className="card-author">
                            <img src="./assets/img/blog1.jpg" alt="author avatar" />
                            <div className="author-info">
                                <p className="author-name">John Doe</p>
                                        <p className="post-timestamp">2h ago</p>
                                <Link className="learn-more pointer" to="https://helloentrepreneurs.in/prop-tech-startup-propdial-forays-into-2nd-home-shared-ownership-space/">
                                    Read More
                                </Link>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="card-container av_single">
                        <div className="card-image">
                            <video src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/videos%2Feditprop.mp4?alt=media&token=fcc9972d-c2ef-48a3-a815-46a32562919d" controls poster="./assets/img/editprop.jpg"></video>
                        </div>
                        <div className="card-body">
                            {/* <span className="card-badge">Business</span> */}
                            {/* <div className="blog_source">
                            <img src="./assets/img/blog_source1.webp" alt="" />
                        </div> */}
                            <h3>
                                How to edit your property

                            </h3>
                            <p className="card-subtitle">
                                Once logged in, navigate to the properties page, Click on toggle and select my property, Click on edit icon.
                            </p>
                            {/* <div className="card-author">
                            <img src="./assets/img/blog1.jpg" alt="author avatar" />
                            <div className="author-info">
                                <p className="author-name">John Doe</p>
                                        <p className="post-timestamp">2h ago</p>
                                <Link className="learn-more pointer" to="https://helloentrepreneurs.in/prop-tech-startup-propdial-forays-into-2nd-home-shared-ownership-space/">
                                    Read More
                                </Link>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="card-container av_single">
                        <div className="card-image">
                            <video src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/videos%2Fsearch.mp4?alt=media&token=93f9a0ed-6dd3-4781-bea9-d37d45d86be1" controls poster="./assets/img/searchprop.png"></video>
                        </div>
                        <div className="card-body">
                            {/* <span className="card-badge">Business</span> */}
                            {/* <div className="blog_source">
                            <img src="./assets/img/blog_source1.webp" alt="" />
                        </div> */}
                            <h3>
                                How to search property

                            </h3>
                            <p className="card-subtitle">
                                Navigate to search section, Search by (City, State, Location) and click on search button.
                            </p>
                            {/* <div className="card-author">
                            <img src="./assets/img/blog1.jpg" alt="author avatar" />
                            <div className="author-info">
                                <p className="author-name">John Doe</p>
                                        <p className="post-timestamp">2h ago</p>
                                <Link className="learn-more pointer" to="https://helloentrepreneurs.in/prop-tech-startup-propdial-forays-into-2nd-home-shared-ownership-space/">
                                    Read More
                                </Link>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div className="verticall_gap"></div>
                <div className="verticall_gap"></div>
            </div>

        </div>

    )
}

export default HowToUse
