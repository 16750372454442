import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useLogout } from "../../../hooks/useLogout";
import Filters from "../../../Components/Filters";
import PropAgentPropertyCard from "./PropAgentPropertyCard";

const propertyFilterAdmin = ["ACTIVE", "INACTIVE", "PENDING APPROVAL", "ADMIN"];
const propertyFilter = ["ACTIVE", "INACTIVE", "PENDING APPROVAL"];

const PropAgentMyProperties = ({ propSearchFilter }) => {
    // const { state } = useLocation();
    // const { propSearchFilter } = state;
    // const { filterType } = state;
    // console.log('propSearchFilter', propSearchFilter)
    // console.log('filterType', filterType)

    // Scroll to the top of the page whenever the location changes start
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    // Scroll to the top of the page whenever the location changes end

    const { user } = useAuthContext();
    const { logout, isPending } = useLogout();
    // const [properties, setProperties] = useState([]);
    const [filter, setFilter] = useState(propSearchFilter !== '' ? propSearchFilter : "ACTIVE");
    const [propertyPurposeFilter, setPropertyPurposeFilter] = useState('Available for Rent')
    const [propertyPurposeFilteredCount, setpropertyPurposeFilteredCount] = useState({
        availableForRentFilteredCount: 0,
        rentedOutFilteredCount: 0,
        availableForSaleFilteredCount: 0,
        soldOutFilteredCount: 0
    })

    // console.log('user:', user)
    const { documents: dbpropertiesdocuments, error: dbpropertieserror } =
        useCollection("properties", ["postedBy", "==", "Agent"]);

    // console.log('dbpropertiesdocuments:', dbpropertiesdocuments)

    // const propertiesdocuments = user && user.role === 'propagentadmin' ? (dbpropertiesdocuments) : (dbpropertiesdocuments && dbpropertiesdocuments.filter(
    //     (item) => item.createdBy.id === user.uid))

    const propertiesdocuments = user && user.role === 'propagentadmin' ? (dbpropertiesdocuments) : (dbpropertiesdocuments && dbpropertiesdocuments.filter(
        (item) => item.propertyManagerID === user.uid))

    // console.log('propertiesdocuments:', propertiesdocuments)

    const { documents: adminUserList, error: adminUserListerror } =
        useCollection("users", ["rolePropAgent", "==", "propagentadmin"]);

    // console.log('filer in getProperties', filter)    
    let properties1 = propertiesdocuments
        ? propertiesdocuments.filter((document) => {
            let filteredProperty = false;
            switch (filter) {
                case "ACTIVE":
                    // document.status.trim().toUpperCase() === "ACTIVE" && document.purpose === propertyPurposeFilter
                    document.status.trim().toUpperCase() === "ACTIVE"
                        ? (filteredProperty = true)
                        : (filteredProperty = false);

                    return filteredProperty;
                case "INACTIVE":
                    document.status.trim().toUpperCase() === "INACTIVE"
                        ? (filteredProperty = true)
                        : (filteredProperty = false);

                    return filteredProperty;

                case "PENDING APPROVAL":
                    document.status.trim().toUpperCase() === "PENDING APPROVAL"
                        ? (filteredProperty = true)
                        : (filteredProperty = false);

                    return filteredProperty;

                case "ADMIN":

                    adminUserList.some(obj2 => obj2.id === document.propertyManagerID) ? (filteredProperty = true)
                        : (filteredProperty = false);

                    // const propertiesAllocatedToAdmin = propertiesdocuments && propertiesdocuments.filter(obj1 => {
                    //     return adminUserList.some(obj2 => obj2.id === obj1.propertyManagerID);
                    // });

                    return filteredProperty;

                default:
                    return true;
            }
        })
        : null;
    // console.log('pending properties:', properties1)

    let properties = properties1
        ? properties1.filter((document) => {
            let filteredProperty = false;
            switch (propertyPurposeFilter) {
                case "Available for Rent":
                    // console.log('switch: ', propertyPurposeFilter)
                    // document.status.trim().toUpperCase() === "ACTIVE" && document.purpose === propertyPurposeFilter
                    document.purpose === "Available for Rent"
                        ? (filteredProperty = true)
                        : (filteredProperty = false);

                    return filteredProperty;
                case "Rented Out":
                    // console.log('switch: ', propertyPurposeFilter)
                    document.purpose === "Rented Out"
                        ? (filteredProperty = true)
                        : (filteredProperty = false);

                    return filteredProperty;

                case "Available for Sale":
                    // console.log('switch: ', propertyPurposeFilter)
                    document.purpose === "Available for Sale"
                        ? (filteredProperty = true)
                        : (filteredProperty = false);

                    return filteredProperty;

                case "Sold Out":
                    // console.log('switch: ', propertyPurposeFilter)
                    document.purpose === "Sold Out"
                        ? (filteredProperty = true)
                        : (filteredProperty = false);

                    return filteredProperty;

                default:
                    return true;
            }
        })
        : null;

    // var cityListStateWise = distinctCityList.filter((e) => e.state === statename);


    var arrAvailableForRent = properties1 && properties1.filter(function (el) {
        return (el.purpose === "Available for Rent");
    });
    // console.log('arrAvailableForRent length:', arrAvailableForRent && arrAvailableForRent.length)

    var arrAvailableForSale = properties1 && properties1.filter(function (el) {
        return (el.purpose === "Available for Sale");
    });
    // console.log('arrAvailableForSale length:', arrAvailableForSale && arrAvailableForSale.length)

    var arrRentedOut = properties1 && properties1.filter(function (el) {
        return (el.purpose === "Rented Out");
    });
    // console.log('arrRentedOut length:', arrRentedOut && arrRentedOut.length)

    var arrSoldOut = properties1 && properties1.filter(function (el) {
        return (el.purpose === "Sold Out");
    });
    // console.log('arrSoldOut length:', arrSoldOut && arrSoldOut.length)


    const changeFilter = (newFilter) => {
        // console.log('changeFilter')
        setFilter(newFilter);
    };

    const propertyPurposeFilterClick = (clickedPurpose) => {
        // console.log('clickedPurpose:', clickedPurpose)
        setPropertyPurposeFilter(clickedPurpose)
    }


    return (
        <div className="propagent_myproperties">
            <div className="">
                {properties &&
                    (filter === "BYME" ||
                        filter === "ACTIVE" ||
                        filter === "INACTIVE" ||
                        filter === "PENDING APPROVAL" ||
                        filter === "ADMIN")
                    && user.role === 'propagentadmin' ?
                    <Filters
                        changeFilter={changeFilter}
                        filterList={propertyFilterAdmin}
                        activeFilter={filter}
                        filterLength={properties1 && properties1.length}
                    /> :
                    <Filters
                        changeFilter={changeFilter}
                        filterList={propertyFilter}
                        activeFilter={filter}
                        filterLength={properties1 && properties1.length}
                    />
                }

                <br></br>

                {properties && <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
                    <span className="no-floating">Filter</span>

                    <div className="radio_group" style={{ paddingTop: "10px" }}>
                        {arrAvailableForRent && arrAvailableForRent.length > 0 && <div className="radio_group_single" style={{ position: 'relative', paddingTop: '5px', paddingBottom: '5px' }}>

                            <div className="purpose-filter-count-tag">
                                <small>{arrAvailableForRent && arrAvailableForRent.length}</small>
                            </div>

                            <div
                                className={
                                    propertyPurposeFilter === "Available for Rent"
                                        ? "custom_radio_button radiochecked"
                                        : "custom_radio_button"
                                }
                            >
                                <input
                                    type="checkbox"
                                    id="purpose_availableforrent"
                                    onClick={(e) => propertyPurposeFilterClick('Available for Rent')}

                                />
                                <label htmlFor="purpose_availableforrent" style={{ paddingTop: "7px" }}>
                                    <div className="radio_icon">
                                        <span className="material-symbols-outlined add">add</span>
                                        <span className="material-symbols-outlined check">
                                            done
                                        </span>
                                    </div>
                                    <span style={{ fontSize: '0.8rem' }}>Available for Rent</span>
                                </label>
                            </div>
                        </div>}
                        {arrRentedOut && arrRentedOut.length > 0 && <div className="radio_group_single" style={{ position: 'relative', paddingTop: '5px', paddingBottom: '5px' }}>

                            <div className="purpose-filter-count-tag">
                                <small>{arrRentedOut && arrRentedOut.length}</small>
                            </div>

                            <div
                                className={

                                    propertyPurposeFilter === "Rented Out"
                                        ? "custom_radio_button radiochecked"
                                        : "custom_radio_button"
                                }
                            >
                                <input
                                    type="checkbox"
                                    id="purpose_rentedout"
                                    onClick={(e) => propertyPurposeFilterClick('Rented Out')}
                                />
                                <label htmlFor="purpose_rentedout" style={{ paddingTop: "7px" }}>
                                    <div className="radio_icon">
                                        <span className="material-symbols-outlined add">add</span>
                                        <span className="material-symbols-outlined check">
                                            done
                                        </span>
                                    </div>
                                    <span style={{ fontSize: '0.8rem' }}>Rented Out</span>
                                </label>
                            </div>
                        </div>}
                        {arrAvailableForSale && arrAvailableForSale.length > 0 && <div className="radio_group_single" style={{ position: 'relative', paddingTop: '5px', paddingBottom: '5px' }}>

                            <div className="purpose-filter-count-tag">
                                <small>{arrAvailableForSale && arrAvailableForSale.length}</small>
                            </div>

                            <div
                                className={

                                    propertyPurposeFilter === "Available for Sale"
                                        ? "custom_radio_button radiochecked"
                                        : "custom_radio_button"
                                }
                            >
                                <input
                                    type="checkbox"
                                    id="purpose_availableforsale"
                                    onClick={(e) => propertyPurposeFilterClick('Available for Sale')}
                                />
                                <label htmlFor="purpose_availableforsale" style={{ paddingTop: "7px" }}>
                                    <div className="radio_icon">
                                        <span className="material-symbols-outlined add">add</span>
                                        <span className="material-symbols-outlined check">
                                            done
                                        </span>
                                    </div>
                                    <span style={{ fontSize: '0.8rem' }}>Available for Sale</span>
                                </label>
                            </div>
                        </div>}
                        {arrSoldOut && arrSoldOut.length > 0 && <div className="radio_group_single" style={{ position: 'relative', paddingTop: '5px', paddingBottom: '5px' }}>

                            <div className="purpose-filter-count-tag">
                                <small>{arrSoldOut.length}</small>
                            </div>

                            <div
                                className={

                                    propertyPurposeFilter === "Sold Out"
                                        ? "custom_radio_button radiochecked"
                                        : "custom_radio_button"
                                }
                            >
                                <input
                                    type="checkbox"
                                    id="purpose_soldout"
                                    onClick={(e) => propertyPurposeFilterClick('Sold Out')}
                                />
                                <label htmlFor="purpose_soldout" style={{ paddingTop: "7px" }}>
                                    <div className="radio_icon">
                                        <span className="material-symbols-outlined add">add</span>
                                        <span className="material-symbols-outlined check">
                                            done
                                        </span>
                                    </div>
                                    <span style={{ fontSize: '0.8rem' }}>Sold Out</span>
                                </label>
                            </div>
                        </div>}
                    </div>
                </div>}
                <hr></hr>

                {properties && properties.length === 0 && (
                    <h2 className="p_title">No property available</h2>
                )}

                <div className="row no-gutters">
                    {properties &&
                        properties.map((property) => (
                            <PropAgentPropertyCard
                                key={property.id}
                                property={property}
                            />
                        ))}
                </div>
                <div className="verticall_gap"></div>
                <div className="verticall_gap_991"></div>
            </div>
        </div>
    )

}

export default PropAgentMyProperties;