import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useDocument } from "../../../hooks/useDocument";
import { useFirestore } from "../../../hooks/useFirestore";
import { timestamp } from "../../../firebase/config";
import Adcarousel from "../../../Components/Ads";
import SearchBarAutoComplete from "../../search/SearchBarAutoComplete";
import { useCollection } from "../../../hooks/useCollection";
// import { useDocument } from './useDocument';

const PropAgentPropertyCard = ({ property }) => {
  // Scroll to the top of the page whenever the location changes start
  // console.log('property:', property)
  const location = useLocation();
  // console.log('property.imgURL:', property.imgURL)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // Scroll to the top of the page whenever the location changes end

  const { user } = useAuthContext();
  // console.log('user: ', user)

  const [userList, setUserList] = useState([]);

  const { documents: dbUsers, error: dbuserserror } =
    useCollection("users", ["status", "==", "active"]);

  // console.log("dbUsers: ", dbUsers)

  const { updateDocument, response: updateDocumentResponse } =
    useFirestore("properties");

  const { document: userDoc, error: userDocError } = useDocument('users', property.propertyManagerID)

  // console.log("userDoc: ", userDoc)
  // console.log("user Phonenmber: ", userDoc && userDoc.phoneNumber)

  // read more read less
  // const [height, setHeight] = useState(true);
  const [toggleFlag, setToggleFlag] = useState(false);
  // const [userSwitch, setUserSwitch] = useState(); //by me/by other

  // const handleHeight = () => {
  //   setHeight(!height);
  // };
  // read more read less

  useEffect(() => {

    if (property.status.toUpperCase() === "ACTIVE") setToggleFlag(false);
    else setToggleFlag(true);

    dbUsers &&
      dbUsers.map((doc) => {
        userList.push({
          id: doc.id,
          fullName: doc.fullName,
          phoneNumber: doc.phoneNumber
        });
      });

  }, [property, dbUsers]);

  const toggleBtnClick_Active_Inactive = async () => {
    let userSwitch = "";
    // e.preventDefault()

    if (toggleFlag) {
      userSwitch = "active";
    } else {
      userSwitch = "inactive";
    }
    setToggleFlag(!toggleFlag);

    const updatedBy = {
      id: user.uid,
      displayName: user.displayName + "(" + user.role + ")",
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      emailID: user.email,
      photoURL: user.photoURL,
    };

    const updatedProperty = {
      status: userSwitch,
      updatedAt: timestamp.fromDate(new Date()),
      updatedBy,
    };

    // console.log("updatedProperty", updatedProperty);
    // console.log('property id: ', property.id)

    await updateDocument(property.id, updatedProperty);
  };

  const toggleBtnClick_Active_PendingApproval = async () => {
    let userSwitch = "";
    // e.preventDefault()

    if (toggleFlag) {
      userSwitch = "active";
    } else {
      userSwitch = "pending approval";
    }
    setToggleFlag(!toggleFlag);

    // console.log("userSwitch value:", userSwitch)
    const updatedBy = {
      id: user.uid,
      displayName: user.displayName + "(" + user.role + ")",
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      emailID: user.email,
      photoURL: user.photoURL,
    };

    const updatedProperty = {
      status: userSwitch,
      updatedAt: timestamp.fromDate(new Date()),
      updatedBy,
    };

    // console.log('updatedProperty', updatedProperty)
    // console.log('property id: ', property.id)

    await updateDocument(property.id, updatedProperty);
  };

  // show less and show more detail
  const [showMore, setShowMore] = useState(true);

  // use useEffect for condition false
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setShowMore(false);
      } else {
        setShowMore(true);
      }
    };

    // Set initial state based on window width
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  // const toggleShowMore = () => {
  //   setShowMore(!showMore);
  // };
  // show less and show more detail

  // expand more expand less start
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  // const parentClassName = `propagentusersingle ${expanded ? "showmore" : ""}`;
  // sexpand more expand less end

  //---------------- Change Property Manager ----------------------
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(dbUsers);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [changeManagerPopup, setchangeManagerPopup] = useState(false);

  const openChangeManager = () => {
    console.log("Open Change Manager");
    setchangeManagerPopup(true);
  }
  const closeChangeManager = () => {
    setchangeManagerPopup(false);
  }

  const confirmChangeManager = async () => {
    // console.log('In confirmChangeManager: ')
    // console.log('selectedUser: ', selectedUser)

    const updatedProperty = {
      propertyManagerID: selectedUser,
      updatedAt: timestamp.fromDate(new Date()),
      updatedBy: user.uid
    };

    // console.log('updatedProperty', updatedProperty)
    // console.log('property id: ', property.id)

    await updateDocument(property.id, updatedProperty);

    setchangeManagerPopup(false);
  }

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterUsers(query);
  };

  const filterUsers = (query) => {
    // console.log('query: ', query)
    const filtered = dbUsers && dbUsers.filter((user) =>
      (user.fullName.toLowerCase().includes(query.toLowerCase()) || (user.phoneNumber.includes(query)))
    );
    setFilteredUsers(filtered);
  };

  // Multiselection for checkbox
  // const handleUserSelect = (userId) => {
  //   const index = selectedUsers.indexOf(userId);
  //   if (index === -1) {
  //     setSelectedUsers([...selectedUsers, userId]);
  //   } else {
  //     const updatedSelectedUsers = [...selectedUsers];
  //     updatedSelectedUsers.splice(index, 1);
  //     setSelectedUsers(updatedSelectedUsers);
  //   }
  // };

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
  };

  // console.log("filteredUsers: ", filteredUsers)
  // console.log("selectedUser: ", selectedUser)

  return (

    <>
      {/* Change Property Manager Popup */}
      <div className={changeManagerPopup ? 'pop-up-change-number-div open' : 'pop-up-change-number-div'}>
        <div className="direct-div">
          <span onClick={closeChangeManager} className="material-symbols-outlined close-button">
            close
          </span>
          <h1 style={{ color: 'var(--theme-orange)', fontSize: '1.4rem' }}>Change Property Manager</h1>
          <br></br>
          {/* <div>          
            <SearchBarAutoComplete              
              enabled={false}
              dataList={userList ? userList : []}
              placeholderText={"Search mobile or name"}
              getQuery={setSearchedUser}
              queryValue={"atul"}
              setRedirectFlag={setRedirectFlag}
            ></SearchBarAutoComplete>
          </div> */}
          <div>
            <input style={{ background: '#efefef', height: '60px' }}
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search users..."
            />
            {/* <ul>
              {filteredUsers && filteredUsers.map((user) => (
                <li key={user.id}>{user.fullName} ({user.phoneNumber.replace(/(\d{2})(\d{5})(\d{5})/, '+$1 $2-$3')})</li>
              ))}
            </ul> */}
            <ul style={{ padding: '10px 0 10px 0' }}>
              {filteredUsers && filteredUsers.map((user) => (
                <li style={{ padding: '10px 0 10px 0' }} key={user.id}>
                  <label style={{
                    fontSize: "1rem", display: 'flex', alignItems: 'center', position: 'relative', width: '100%', background: '#efefef', padding: '10px 0 10px 0', margin: '0'
                  }}>
                    <input style={{ width: '10%' }}
                      name="selectedUser"
                      // type="checkbox"
                      // checked={selectedUsers.includes(user.id)}
                      type="radio"
                      checked={selectedUser === user.id}
                      onChange={() => handleUserSelect(user.id)}
                    />
                    {user.fullName} ({user.phoneNumber.replace(/(\d{2})(\d{5})(\d{5})/, '+$1 $2-$3')})
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div id="id_sendotpButton" className="change-number-button-div">
            <button onClick={closeChangeManager} className="mybutton button5" style={{ background: "#afafaf" }}>Cancel</button>
            <button onClick={confirmChangeManager} className="mybutton button5">Confirm</button>
          </div>
        </div>
      </div >

      <div className="col-xl-6 col-lg-12">
        <div className="ppc_single" key={property.id}>
          <div className="ppc_single_top relative">
            {user && (user.uid === property.propertyManagerID || user.role === 'propagentadmin') ? (
              <Link
                className="prop_edit"
                to={`/pgaddproperty/${property.id}`}
                key={property.id}
              >
                <span className="material-symbols-outlined">edit_square</span>
              </Link>
            ) : (
              ""
            )}
            {user &&
              user.role === "propagent" &&
              property.propertyManagerID !== user.uid ? (
              <div className="fav_and_share">
                <span
                  className="material-symbols-outlined mr-2 fav"
                  style={{
                    marginRight: "3px",
                  }}
                >
                  favorite
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="img_container" style={{ overflow: 'hidden' }}>
              {property.imgURL.length > 0 ? <Adcarousel imgURLs={property.imgURL}></Adcarousel> : <img alt="" src="/assets/img/default_property_image.jpg"></img>}

            </div>
            <div className="left_side relative">


              <h5 className="demand">
                <span>₹</span> <span>{property.demandprice}</span>
                {property.maintenancecharges === '' || property.maintenancecharges === '0' || property.purpose === 'Available for Sale' || property.purpose === 'Sold Out' ? "" : <> <span style={{ fontSize: '10px' }}> +
                  ₹{property.maintenancecharges} ({property.maintenancechargesfrequency})</span> </>}
              </h5>
              <div className="full_address">
                <h6>
                  {((property.purpose.toUpperCase() === 'AVAILABLE FOR RENT') || (property.purpose.toUpperCase() === 'AVAILABLE FOR SALE')) ? <span style={{ textAlign: 'center', color: 'black', fontWeight: "bolder", padding: '2px 8px', borderRadius: '8px', background: 'lightgreen' }} > {property.purpose}</span> : <span style={{ textAlign: 'center', color: 'black', fontWeight: "bolder", padding: '2px 8px', borderRadius: '8px', background: 'lightpink' }} > {property.purpose}</span>}
                </h6>
                <h6>{property.bhk} {property.propertyType}</h6>
                <h6>
                  {property.society} | {property.locality}
                </h6>
                <h6>
                  {property.city} | {property.state}
                </h6>
              </div>
            </div>
          </div>
          <div className="ppc_single_middle_parent relative">
            <div className="show_more_arrow" onClick={handleExpand}>
              <span className="material-symbols-outlined">
                {expanded ? "keyboard_arrow_down" : "keyboard_arrow_up"}
              </span>
            </div>
            <div className="ppc_single_middle">

              <div className="ppcsm_single">
                <div className="icon_container">
                  <img src="/assets/img/new_bedroom.png" alt="" />
                </div>
                <div className="left">
                  <h6>Bedroom</h6>
                  <h5>{property.numberOfBedrooms}</h5>
                </div>
              </div>
              <div className="ppcsm_single">
                {/* <div className="icon_container">
                <img src="/assets/img/new_carpet.png" alt="" />
              </div>
              <div className="left">
                <h6>Super Area</h6>
                <h5>{property.superArea}  {property.superAreaUnit}</h5>
              </div> */}
              </div>
              <div className="ppcsm_single">
                <div className="icon_container">
                  <img src="/assets/img/new_bathroom.png" alt="" />
                </div>
                <div className="left">
                  <h6>Bathroom</h6>
                  <h5>{property.numberOfBathrooms}</h5>
                </div>
              </div>
            </div>
            {expanded ? (
              ""
            ) : (
              <div className="ppc_single_middle">
                <div className="ppcsm_single">
                  <div className="icon_container">
                    {property.superArea ? <img src="/assets/img/new_super_area.png" alt="" /> : <img src="/assets/img/new_carpet.png" alt="" />}
                  </div>
                  <div className="left">
                    {property.superArea ? <h6>Super Area</h6> : <h6>Carpet Area</h6>}
                    {property.superArea ? <h5>{property.superArea} {property.superAreaUnit}</h5> : <h5>{property.carpetArea} {property.superAreaUnit}</h5>}
                  </div>
                </div>
                <div className="ppcsm_single">
                  {/* <div className="icon_container">
                  <img src="/assets/img/new_bhk.png" alt="" />
                </div>
                <div className="left">
                  <h6>BHK</h6>
                  <h5>{property.bhk}</h5>
                </div> */}
                </div>
                <div className="ppcsm_single">
                  <div className="icon_container">
                    <img src="/assets/img/new_furniture.png" alt="" />
                  </div>
                  <div className="left">
                    <h6>furnishing</h6>
                    <h5>{property.furnishing}</h5>
                  </div>
                </div>
              </div>
            )}
            {expanded ? (
              ""
            ) : (
              property.additionalRooms.length > 0 && <div div className="ppc_single_middle addtional_rooms">
                <div className="ppcsm_single">
                  <div className="icon_container">
                    <img src="/assets/img/new_room.png" alt="" />
                  </div>
                  <div className="left">
                    <h6>Additional rooms</h6>
                    <h5>
                      {property.additionalRooms.map((room, index) => (
                        <span key={index}>{room}</span>
                      ))}
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="ppc_single_bottom">
            {/* Temparary Code: Need to remove */}
            {/* {property && <div>
              {property.createdBy.fullName}-{property.createdBy.phoneNumber}-{property.createdBy.id}
            </div>} */}
            {/* Details: {property && (property.createdBy.fullName) && (property.createdBy.phoneNumber)} */}
            {user &&
              ((user.role === "propagent" && property.propertyManagerID !== user.uid) ||
                user.role === "propagentadmin") ? (
              <div className="property_owner_detail">
                <div className="img_container">
                  {userDoc && <img src={userDoc && userDoc.photoURL} alt="" />}
                </div>
                <div className="pod_right">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {userDoc && <h5>{userDoc && userDoc.fullName}</h5>}
                    {
                      user && user.role === "propagentadmin" &&
                      <div>
                        <small onClick={openChangeManager} style={{ paddingLeft: '10px', fontSize: '0.8rem', color: '#5a99cc', cursor: 'pointer' }}>change</small>
                        <span
                          className="material-symbols-outlined"
                          onClick={openChangeManager}
                          style={{ fontSize: '0.8rem', color: '#5a99cc', cursor: 'pointer' }}
                        >
                          edit
                        </span>
                      </div>}

                  </div>

                  <h6>
                    {userDoc && <a href={"tel:" + userDoc && userDoc.phoneNumber} className="phone">
                      +{userDoc && userDoc.phoneNumber}
                    </a>}

                    {userDoc && <a href={"tel:" + userDoc && userDoc.phoneNumber} className="call whatsapp">
                      <img
                        src="/assets/img/phone-call.png"
                        style={{ width: "25px", height: "25px", marginLeft: "6px" }}
                        alt=""
                      />
                    </a>}
                    {userDoc && <a href={"https://wa.me/" + userDoc && userDoc.phoneNumber} className="whatsapp">
                      <img
                        src="/assets/img/whatsapp.png"
                        style={{ width: "25px", height: "25px", marginLeft: "6px" }}
                        alt=""
                      />
                    </a>}
                  </h6>

                </div>
              </div>
            ) : (
              ""
            )}

            {user && user.uid === property.propertyManagerID && (
              <>
                {property.status !== "pending approval" && (
                  <div
                    className="residential-commercial-switch property_active_inactive"
                    style={{ top: "0", marginLeft: "auto" }}
                  >
                    <span
                      className={property.status === "active" ? "active" : " "}
                      style={{
                        color: "var(--p-theme-green)",
                        fontSize: "0.8rem",
                        fontFamily: "r_medium"
                      }}
                    >
                      ACTIVE
                    </span>
                    <div
                      className={
                        toggleFlag
                          ? "toggle-switch on commercial"
                          : "toggle-switch off residential"
                      }
                      style={{ padding: "0 5px" }}
                    >
                      {/* <small>{toggleFlag ? 'On' : 'Off'}</small> */}
                      {/* <div onClick={toggleBtnClick()}> */}
                      <div onClick={() => toggleBtnClick_Active_Inactive()}>
                        <div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <span
                      className={property.status === "inactive" ? "active" : " "}
                      style={{
                        color: "var(--p-theme-grey)",
                        fontSize: "0.8rem",
                        fontFamily: "r_medium"
                      }}
                    >
                      INACTIVE
                    </span>
                  </div>
                )}
              </>
            )}

            {user.role === "propagentadmin" && property.status !== "inactive" && (
              <div className="residential-commercial-switch" style={{ top: "0" }}>
                <span
                  // className={toggleFlag ? "" : "active"}
                  className={property.status === "active" ? "active" : " "}
                  style={{
                    color: "var(--p-theme-green)",
                    fontSize: "0.8rem",
                  }}
                >
                  ACTIVE
                </span>
                <div
                  className={
                    toggleFlag
                      ? "toggle-switch on commercial"
                      : "toggle-switch off residential"
                  }
                  // className={
                  //   property.status === 'pending approval'
                  //     ? "toggle-switch on commercial"
                  //     : "toggle-switch off residential"
                  // }
                  style={{ padding: "0 5px" }}
                >
                  <div onClick={() => toggleBtnClick_Active_PendingApproval()}>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <span
                  // className={toggleFlag ? "active" : ""}
                  className={
                    property.status === "pending approval" ? "active" : " "
                  }
                  style={{
                    color: "var(--theme-orange)",
                    fontSize: "0.8rem",
                  }}
                >
                  PENDING APPROVAL
                </span>
              </div>
            )}
          </div>

        </div>
      </div >
    </>

  );
};

export default PropAgentPropertyCard;
