import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDocument } from "../../../hooks/useDocument";
import Stage1 from './Stage1'
import Stage2 from './Stage2'
import Stage3 from './Stage3'

export default function PGAddProperty() {
    const { propertyid } = useParams();
    const [propertyObj, setPropertyObj] = useState(null)
    const [stageFlag, setStageFlag] = useState('stage1')

    // const { document: propertyDocument, error: propertyerror } = useDocument(
    //     "properties",
    //     propertyid
    // );



    // useEffect(() => {
    //     propertyid === 'new' ? setPropertyObj(null) : setPropertyObj(propertyDocument)
    //     console.log('propertyObj  in PGAddProperty:', propertyObj)
    // }, [propertyDocument])

    // console.log('PGAddProperty : stageFlag:', stageFlag)
    // console.log('PGAddProperty : propertyObj:', propertyObj)

    return (
        <>
            <div className="top_header_pg pa_bg add_property_stages">
                <div className="pa_inner_page pb_for_nextbtn">
                    <div className="pg_header">
                        <h2 className="p_title">Add Property</h2>
                        <h4 className="p_subtitle">You can add your property here</h4>
                    </div>
                    <div className="verticall_gap"></div>
                    {stageFlag === 'stage1' && <Stage1 setPropertyObj={setPropertyObj} setStateFlag={setStageFlag}></Stage1>}
                    {stageFlag === 'stage2' && <Stage2 propertyObj={propertyObj} setPropertyObj={setPropertyObj} setStateFlag={setStageFlag}></Stage2>}
                    {stageFlag === 'stage3' && <Stage3 propertyObj={propertyObj} setPropertyObj={setPropertyObj} setStateFlag={setStageFlag}></Stage3>}

                </div>

            </div>


        </>
    )
}

