import React from "react";
import Select from "react-select";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCommon } from "../../../hooks/useCommon";
import { useDocument } from "../../../hooks/useDocument";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useFirestore } from "../../../hooks/useFirestore";
import { useCollection } from "../../../hooks/useCollection";

import { timestamp, projectStorage, projectID } from "../../../firebase/config";
import SearchBarAutoComplete from "../../search/SearchBarAutoComplete";
// import { projectID } from 'firebase-functions/params';

//Restrict to Input
function restrictInput(event, maxLength) {
  // Get the value entered in the input field
  let inputValue = event.target.value;

  // Remove any non-numeric characters using a regular expression
  let numericValue = inputValue.replace(/[^0-9]/g, "");

  // Limit the maximum length to 10 characters
  // let maxLength = 9;
  if (numericValue.length > maxLength) {
    numericValue = numericValue.slice(0, maxLength);
  }

  // Update the input field with the numeric value
  event.target.value = numericValue;
}

//Convert Amount to Words - Starts

const units = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
const teens = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
const tens = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

function convertToWords(number) {
  if (number === 0) {
    return "zero";
  }

  // Split the number into groups of three digits
  const chunks = [];
  while (number > 0) {
    chunks.push(number % 1000);
    number = Math.floor(number / 1000);
  }

  // Convert each chunk to words
  const chunkWords = chunks.map((chunk, index) => {
    if (chunk === 0) {
      return "";
    }

    const chunkText = chunkToWords(chunk);
    const suffix = index === 0 ? "" : ` ${indexToPlace(index)}`;
    return `${chunkText}${suffix}`;
  });

  // Combine the chunk words
  return chunkWords.reverse().join(" ").trim();
}

function chunkToWords(chunk) {
  const hundredDigit = Math.floor(chunk / 100);
  const remainder = chunk % 100;

  let result = "";
  if (hundredDigit > 0) {
    result += `${units[hundredDigit]} hundred`;
  }

  if (remainder > 0) {
    if (result !== "") {
      result += " and ";
    }

    if (remainder < 10) {
      result += units[remainder];
    } else if (remainder < 20) {
      result += teens[remainder - 10];
    } else {
      const tenDigit = Math.floor(remainder / 10);
      const oneDigit = remainder % 10;

      result += tens[tenDigit];
      if (oneDigit > 0) {
        result += `-${units[oneDigit]}`;
      }
    }
  }

  return result;
}

function indexToPlace(index) {
  const places = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
  ];
  return places[index];
}

// Example usage:
// const amount = 12000;
// const amountInWords = convertToWords(amount);
// console.log(`${amount} in words: ${amountInWords}`);
//Convert Amount to Words - Ends

// function formatAmount(amount) {
//   // Example: Add dashes
//   return amount.toLocaleString('en-US');
// }
// function formatAmount(event) {
//   // Example: Add dashes
//   let inputValue = event.target.value
//   return inputValue.toLocaleString('en-US');
// }

function camelCase(inputStr) {
  let str = inputStr.toLowerCase();
  return (
    str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      // .replace(/\s/g, '')
      .replace(/^(.)/, function (b) {
        return b.toUpperCase();
      })
  );
}

const Stage1 = (props) => {
  const { propertyid } = useParams();
  // console.log('firebase projectid:', projectID)

  const { document: propertyDocument, error: propertyerror } = useDocument(
    "properties",
    propertyid
  );
  function setRedirectFlag(flag, key) {


  }
  const { user } = useAuthContext();

  let statesOptions = useRef([]);
  let statesOptionsSorted = useRef([]);
  let citiesOptions = useRef([]);
  let citiesOptionsSorted = useRef([]);
  var distinctCityList = [];
  var distinctLocalityList = [];
  var distinctSocietyList = [];
  const [state, setState] = useState();
  const [city, setCity] = useState({ label: "Select City", value: "Select City" });
  const [distinctValuesLocality, setdistinctValuesLocality] = useState([]);
  const [distinctValuesSociety, setdistinctValuesSociety] = useState([]);
  const [formError, setFormError] = useState(null);
  const [formWarning, setFormWarning] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  // const { amountToWords, response: amountToWordsResponse } = useCommon();
  // const { camelCase } = useCommon();
  // const { formatAmount, response: formatAmountResponse } = useCommon();
  // const { formatPhoneNumber } = useCommon();
  const [distinctValuesCity, setdistinctValuesCity] = useState([]);
  const [onboardingDate, setOnboardingDate] = useState(new Date());
  const [newProperty, setNewProperty] = useState(null);

  const { addDocument, response: addDocumentResponse } =
    useFirestore("properties");
  const { updateDocument, response: updateDocumentResponse } =
    useFirestore("properties");

  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // Scroll to the top of the page whenever the location changes end
  const navigate = useNavigate();

  const { documents: dbpropertiesdocuments, error: propertieserror } =
    useCollection("properties", ["postedBy", "==", "Agent"]);

  const { documents: dbstatesdocuments, error: dbstateserror } = useCollection(
    "m_states",
    ["country", "==", "INDIA"]
  );

  const { documents: dbcitiesdocuments, error: dbcitieserror } = useCollection(
    "m_cities",
    ["status", "==", "active"]
  );

  // Distinct City list from Properties document
  // dbpropertiesdocuments &&
  //   dbpropertiesdocuments.map((doc) => {
  //     if (!distinctCityList.find((e) => e.city === doc.city)) {
  //       distinctCityList.push({
  //         state: doc.state,
  //         city: doc.city,
  //       });
  //     }
  //   });

  //Not required Distinct list, Just fetch Cities from m_cities collection as per the city status as active 
  dbcitiesdocuments &&
    dbcitiesdocuments.map((doc) => {
      // console.log("dbcitiesdocuments: ", dbcitiesdocuments)
      if (!distinctCityList.find((e) => e.city === doc.city)) {
        distinctCityList.push({
          state: doc.state,
          city: doc.city,
        });
      }
    });

  dbpropertiesdocuments &&
    dbpropertiesdocuments.map((doc) => {
      if (!distinctLocalityList.find((e) => e.locality === doc.locality)) {
        distinctLocalityList.push({
          city: doc.city,
          locality: doc.locality,
        });
      }
    });
  // console.log('distinctLocalityList: ', distinctLocalityList)

  dbpropertiesdocuments &&
    dbpropertiesdocuments.map((doc) => {
      if (!distinctSocietyList.find((e) => e.society === doc.society)) {
        distinctSocietyList.push({
          locality: doc.locality,
          society: doc.society,
        });
      }
    });

  const [propertyDetails, setPropertyDetails] = useState({
    // All select type
    Category: "RESIDENTIAL",
    UnitNumber: "",
    DemandPrice: "",
    MaintenanceCharges: "",
    MaintenanceChargesFrequency: "",
    Purpose: "",
    Country: "",
    State: "",
    City: "",
    Locality: "",
    Society: "",
  });

  useEffect(() => {
    statesOptions.current =
      dbstatesdocuments &&
      dbstatesdocuments.map((stateData) => ({
        label: stateData.state,
        value: stateData.state,
      }));
    // // console.log('statesOptions:', statesOptions)
    statesOptionsSorted.current =
      statesOptions.current &&
      statesOptions.current.sort((a, b) => a.label.localeCompare(b.label));

    statesOptionsSorted.current &&
      statesOptionsSorted.current.unshift({
        label: "Select State",
        value: "Select State",
      });

    if (propertyDocument && propertyDocument.state) {
      setState({
        label: propertyDocument.state,
        value: propertyDocument.state,
      });
      handleStateChange({
        label: propertyDocument.state,
        value: propertyDocument.state,
      });
    } else {
      setState({ label: "Select State", value: "Select State" });
      handleStateChange({
        label: "Select State",
        value: "Select State",
      });
    }

    if (propertyDocument && propertyDocument.city) {
      setCity({
        label: propertyDocument.city,
        value: propertyDocument.city,
      });
      handleCityChange({
        label: propertyDocument.city,
        value: propertyDocument.city,
      });
    } else {
      setCity({ label: "Select City", value: "Select City" });
      handleCityChange({
        label: "Select City",
        value: "Select City",
      });
    }

    if (propertyDocument) {
      setPropertyDetails({
        // All select type
        Category: propertyDocument.category
          ? propertyDocument.category
          : "RESIDENTIAL",
        UnitNumber: propertyDocument.unitNumber
          ? propertyDocument.unitNumber
          : "",
        DemandPrice: propertyDocument.demandprice
          ? propertyDocument.demandprice
          : "",
        MaintenanceCharges: propertyDocument.maintenancecharges
          ? propertyDocument.maintenancecharges
          : "",
        MaintenanceChargesFrequency:
          propertyDocument.maintenancechargesfrequency
            ? propertyDocument.maintenancechargesfrequency
            : "NA",
        Purpose: propertyDocument.purpose ? propertyDocument.purpose : "",
        Country: propertyDocument.country ? propertyDocument.country : "",
        State: propertyDocument.state ? propertyDocument.state : "",
        City: propertyDocument.city ? propertyDocument.city : "",
        Locality: propertyDocument.locality ? propertyDocument.locality : "",
        Society: propertyDocument.society ? propertyDocument.society : "",
      });
    }
  }, [dbstatesdocuments, dbpropertiesdocuments, propertyDocument]);

  const setPurpose = (option) => {
    // console.log("setPurpose e.target.value:", option);
    setPropertyDetails({
      ...propertyDetails,
      Purpose: option,
    });

    let obj_maintenance = document.getElementById("id_maintenancecharges");
    option.toUpperCase() === "SALE"
      ? (obj_maintenance.style.display = "none")
      : (obj_maintenance.style.display = "flex");
  };

  const handleStateChange = async (option) => {

    setState(option);
    let statename = option.label;
    // console.log('state name:  ', statename)
    let cityListStateWise = [];
    cityListStateWise = distinctCityList.filter((e) => e.state === statename);
    // console.log('cityListStateWise:', cityListStateWise)

    const dataList =
      cityListStateWise && cityListStateWise.map((doc) => doc.city);
    // distinctValuesCity = [...new Set(dataCity)];
    setdistinctValuesCity([...new Set(dataList)]);
    // console.log('distinctValuesCity:', distinctValuesCity)

    //City Dropdown List as per state
    citiesOptions.current =
      cityListStateWise &&
      cityListStateWise.map((cityData) => ({
        label: cityData.city,
        value: cityData.city,
      }));
    // // console.log('statesOptions:', statesOptions)
    // citiesOptionsSorted = null;

    citiesOptionsSorted.current =
      citiesOptions.current &&
      citiesOptions.current.sort((a, b) => a.label.localeCompare(b.label));

    // citiesOptionsSorted.current &&
    //   citiesOptionsSorted.current.unshift({
    //     label: "Select City",
    //     value: "Select City",
    //   })
    setCity({ label: "Select City", value: "Select City" });
  };

  const handleCityChange = async (option) => {
    setCity(option);

    // console.log('City option: ', option)

    setSearchedCity(option.value)
  }

  function setSearchedCity(cityname) {
    // console.log('cityname', cityname);
    setPropertyDetails({
      ...propertyDetails,
      City: cityname,
    });

    let localityListStateWise = [];
    localityListStateWise = distinctLocalityList.filter(
      (e) => e.city === cityname
    );
    // console.log('localityListStateWise:', localityListStateWise)

    const dataList =
      localityListStateWise && localityListStateWise.map((doc) => doc.locality);
    setdistinctValuesLocality([...new Set(dataList)]);

    console.log('Locality dataList: ', dataList)

  }

  // function addNewLocality(e) {
  const addNewLocality = (e) => {
    e.preventDefault();
    let newLocality = document.getElementById('id_addNewLocality').value;
    console.log('new Locality: ', newLocality)
    setSearchedLocality(newLocality)
    setaddLocalityDiv(false)
  }

  function setSearchedLocality(localityname) {
    // console.log('localityname', localityname);
    setPropertyDetails({
      ...propertyDetails,
      Locality: localityname,
    });
    let societyListStateWise = [];
    societyListStateWise = distinctSocietyList.filter(
      (e) => e.locality === localityname
    );
    // console.log("societyListStateWise:", societyListStateWise);

    const dataList =
      societyListStateWise && societyListStateWise.map((doc) => doc.society);
    setdistinctValuesSociety([...new Set(dataList)]);
  }

  // function addNewSociety(e) {
  const addNewSociety = (e) => {
    e.preventDefault();
    let newSociety = document.getElementById('id_addNewSociety').value;
    // console.log('new Society: ', newSociety)
    setSearchedSociety(newSociety)
    setaddSocietyDiv(false)
  }


  function setSearchedSociety(societyname) {
    // console.log('societyname', societyname);
    setPropertyDetails({
      ...propertyDetails,
      Society: societyname,
    });
  }

  // const cloneImage = async (sourcePath, destinationPath) => {
  //   try {
  //     // Create a reference to the source image in Firebase Storage
  //     const sourceRef = projectStorage.refFromURL(sourcePath);

  //     // Get the download URL of the source image
  //     const downloadURL = await sourceRef.getDownloadURL();

  //     // Create a reference to the destination image in Firebase Storage
  //     const destinationRef = projectStorage.ref().child(destinationPath);

  //     // Copy the image from the source path to the destination path
  //     await destinationRef.putString(downloadURL, 'data_url');

  //     console.log('Image cloned successfully!');
  //   } catch (error) {
  //     console.error('Error cloning image:', error.message);
  //   }
  // }
  useEffect(() => {
    // console.log('addDocumentResponse', addDocumentResponse)
    try {
      if (addDocumentResponse.document) {
        // console.log('addDocumentResponse id', addDocumentResponse.document && addDocumentResponse.document.id)
        props.setPropertyObj({
          ...newProperty,
          id: addDocumentResponse.document && addDocumentResponse.document.id,
          // stageFlag: 'stage2'
        });

        props.setStateFlag("stage2");

        navigate("/pgaddproperty/" + addDocumentResponse.document.id);

        //add a default property image into storage for newly created propertyid
        // const sourceImagePath = 'gs://your-project-id.appspot.com/images/source.jpg';
        // const sourceImagePath = 'gs://' + projectID + '/masterData/icons/icon_propertyDefault.jpg'
        // console.log('sourceImagePath:', sourceImagePath)
        // const destinationImagePath = 'properties/' + addDocumentResponse.document.id + '/Images/' + timestamp.fromDate(new Date()) + ".png";
        // console.log('destinationImagePath:', destinationImagePath)
        // cloneImage(sourceImagePath, destinationImagePath);
      }
    } catch (error) {
      console.error("Error cloning image:", error.message);
    }
  }, [addDocumentResponse]);

  const handleSubmit = async (e, option) => {
    e.preventDefault();
    // console.log('e: ', e)
    // console.log('option: ', option)

    setFormError(null);
    setFormSuccess(null);

    let errorFlag = false;
    let errorMsg = "Please select ";

    if (propertyDetails.Purpose === "") {
      if (errorMsg === "Please select ") errorMsg = errorMsg + "Purpose";
      else errorMsg = errorMsg + ", Purpose";
      errorFlag = true;
    }
    if (propertyDetails.DemandPrice === "" || propertyDetails.DemandPrice === "0") {
      if (errorMsg === "Please select ") errorMsg = "Please Enter Demand Price";
      else errorMsg = errorMsg + ", Demand Price";
      errorFlag = true;
    }

    if (propertyDetails.MaintenanceCharges === "") {
      propertyDetails.MaintenanceChargesFrequency = "NA"
    } else {
      if (
        (propertyDetails.MaintenanceCharges !== "") &&
        propertyDetails.MaintenanceChargesFrequency === ""
      ) {
        if (errorMsg === "Please select ")
          errorMsg = errorMsg + "Frequency";
        else errorMsg = errorMsg + ", Frequency";
        errorFlag = true;
      }
      else {
        if (propertyDetails.MaintenanceCharges !== "" &&
          propertyDetails.MaintenanceChargesFrequency === "NA") {
          if (errorMsg === "Please select ")
            errorMsg = errorMsg + "Frequency";
          else errorMsg = errorMsg + ", Frequency";
          errorFlag = true;
        }
      }
    }

    // console.log('state:', state)
    if (state === "" || state === undefined || state === "Select State") {
      if (errorMsg === "Please select ") errorMsg = errorMsg + "State";
      else errorMsg = errorMsg + ", State";
      errorFlag = true;
    }

    if (propertyDetails.City === "") {
      if (errorMsg === "Please select ") errorMsg = errorMsg + "City";
      else errorMsg = errorMsg + ", City";
      errorFlag = true;
    }
    if (propertyDetails.Locality === "") {
      if (errorMsg === "Please select ") errorMsg = errorMsg + "Locality";
      else errorMsg = errorMsg + ", Locality";
      errorFlag = true;
    }
    if (propertyDetails.Society === "") {
      if (errorMsg === "Please select ") errorMsg = errorMsg + "Society";
      else errorMsg = errorMsg + ", Society";

      errorFlag = true;
    }

    if (errorFlag) setFormError(errorMsg);
    else setFormError("");

    // console.log('propertyDetails.City:', propertyDetails.City)

    const property = {
      category: propertyDetails.Category
        ? propertyDetails.Category
        : "RESIDENTIAL",
      unitNumber: propertyDetails.UnitNumber ? propertyDetails.UnitNumber : "",
      purpose: propertyDetails.Purpose ? propertyDetails.Purpose : "",
      demandprice: propertyDetails.DemandPrice
        ? propertyDetails.DemandPrice
        : "",
      maintenancecharges: propertyDetails.MaintenanceCharges
        ? propertyDetails.MaintenanceCharges
        : "",
      maintenancechargesfrequency: propertyDetails.MaintenanceChargesFrequency
        ? propertyDetails.MaintenanceChargesFrequency
        : "NA",
      state: state.label,
      city: camelCase(propertyDetails.City.toLowerCase().trim()),
      locality: camelCase(propertyDetails.Locality.toLowerCase().trim()),
      society: camelCase(propertyDetails.Society.toLowerCase().trim()),
    };

    if (propertyid === "new") {
      // console.log('Property id while newly added : ', propertyid)
      // console.log("Property: ", property)

      const newProperty = {
        ...property,
        //Stage 2 fields-createhere
        propertyType: "",
        bhk: "",
        numberOfBedrooms: "0",
        numberOfBathrooms: "0",
        furnishing: "",
        additionalRooms: [],
        superArea: "",
        superAreaUnit: "",
        carpetArea: "",
        carpetAreaUnit: "",
        imgURL: [],
        propertyManagerID: user.uid,
        postedBy: "Agent",
        status: "pending approval",
        onboardingDate: timestamp.fromDate(new Date(onboardingDate)),
      };
      if (!errorFlag) {
        await addDocument(newProperty);
        if (addDocumentResponse.error) {
          navigate("/");
        } else {
          setNewProperty(newProperty);
        }
      }
    } else if (propertyid !== "new") {
      const updatedBy = {
        id: user.uid,
        displayName: user.displayName + "(" + user.role + ")",
        fullName: user.fullName,
        phoneNumber: user.phoneNumber,
        emailID: user.email,
        photoURL: user.photoURL,
      };

      const updatedProperty = {
        ...property,
        updatedAt: timestamp.fromDate(new Date()),
        updatedBy,
      };

      if (!errorFlag) {
        await updateDocument(propertyid, updatedProperty);

        if (updateDocumentResponse.error) {
          navigate("/");
        } else {
          if (option === "Save") {
            console.log("option: ", option)
            //Do nothing
            setFormSuccess("Data Saved Successfully");
          }
          else {
            console.log("option: ", option)
            props.setStateFlag("stage2");
          }
        }
      }
    }
  };
  // const handleSaveSubmit = (e) => {
  //   // saveDatainDB("save")
  //   console.log("option: ", option)
  //   console.log("e: ", e)

  // }
  const handleBackSubmit = (e) => {
    // console.log('handleBackSubmit')    
    navigate("/agentproperties", {
      state: {
        propSearchFilter: 'ACTIVE'
      }
    });
  }

  const [addLocalityDiv, setaddLocalityDiv] = useState(false);

  const openAddLocalityDiv = () => {
    setaddLocalityDiv(true);
  }

  const closeAddLocalityDiv = () => {
    setaddLocalityDiv(false);
  }

  const [addSocietyDiv, setaddSocietyDiv] = useState(false);

  const openAddSocietyDiv = () => {
    setaddSocietyDiv(true);
  }

  const closeAddSocietyDiv = () => {
    setaddSocietyDiv(false);
  }


  return (
    // <form onSubmit={handleSubmit}>
    <div>
      <div className="add_property_fields">
        <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">
            Unit Number (Not for public display)
          </span>
          <input
            className="custom-input"
            type="text"
            placeholder="Optional"
            maxLength={30}
            onChange={(e) =>
              setPropertyDetails({
                ...propertyDetails,
                UnitNumber: e.target.value,
              })
            }
            value={propertyDetails && propertyDetails.UnitNumber}
          />
        </div>

        <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">Purpose</span>

          <div className="radio_group" style={{ paddingTop: "10px" }}>
            <div className="radio_group_single">
              <div
                className={
                  propertyDetails.Purpose === "Available for Rent"
                    ? "custom_radio_button radiochecked"
                    : "custom_radio_button"
                }
              >
                <input
                  type="checkbox"
                  id="purpose_availableforrent"
                  onClick={(e) => {
                    setPropertyDetails({
                      ...propertyDetails,
                      Purpose: "Available for Rent",
                    });
                  }}
                />
                <label htmlFor="purpose_availableforrent" style={{ paddingTop: "7px" }}>
                  <div className="radio_icon">
                    <span className="material-symbols-outlined add">add</span>
                    <span className="material-symbols-outlined check">
                      done
                    </span>
                  </div>
                  <span style={{ fontSize: '0.8rem' }}>Available for Rent</span>
                </label>
              </div>
            </div>
            {propertyid !== 'new' && <div className="radio_group_single">
              <div
                className={
                  propertyDetails.Purpose === "Rented Out"
                    ? "custom_radio_button radiochecked"
                    : "custom_radio_button"
                }
              >
                <input
                  type="checkbox"
                  id="purpose_rentedout"
                  onClick={(e) => {
                    setPropertyDetails({
                      ...propertyDetails,
                      Purpose: "Rented Out",
                    });
                  }}
                />
                <label htmlFor="purpose_rentedout" style={{ paddingTop: "7px" }}>
                  <div className="radio_icon">
                    <span className="material-symbols-outlined add">add</span>
                    <span className="material-symbols-outlined check">
                      done
                    </span>
                  </div>
                  <span style={{ fontSize: '0.8rem' }}>Rented Out</span>
                </label>
              </div>
            </div>}
            <div className="radio_group_single">
              <div
                className={
                  propertyDetails.Purpose === "Available for Sale"
                    ? "custom_radio_button radiochecked"
                    : "custom_radio_button"
                }
              >
                <input
                  type="checkbox"
                  id="purpose_availableforsale"
                  onClick={(e) => {
                    setPropertyDetails({
                      ...propertyDetails,
                      Purpose: "Available for Sale",
                    });
                  }}
                />
                <label htmlFor="purpose_availableforsale" style={{ paddingTop: "7px" }}>
                  <div className="radio_icon">
                    <span className="material-symbols-outlined add">add</span>
                    <span className="material-symbols-outlined check">
                      done
                    </span>
                  </div>
                  <span style={{ fontSize: '0.8rem' }}>Available for Sale</span>
                </label>
              </div>
            </div>
            {propertyid !== 'new' && <div className="radio_group_single">
              <div
                className={
                  propertyDetails.Purpose === "Sold Out"
                    ? "custom_radio_button radiochecked"
                    : "custom_radio_button"
                }
              >
                <input
                  type="checkbox"
                  id="purpose_soldout"
                  onClick={(e) => {
                    setPropertyDetails({
                      ...propertyDetails,
                      Purpose: "Sold Out",
                    });
                  }}
                />
                <label htmlFor="purpose_soldout" style={{ paddingTop: "7px" }}>
                  <div className="radio_icon">
                    <span className="material-symbols-outlined add">add</span>
                    <span className="material-symbols-outlined check">
                      done
                    </span>
                  </div>
                  <span style={{ fontSize: '0.8rem' }}>Sold Out</span>
                </label>
              </div>
            </div>}
          </div>
        </div>
        <div
          id="id_demand"
          className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg"
        >
          <span className="no-floating">Demand/Price</span>
          <input
            id="id_demandprice"
            className="custom-input"
            // required
            type="number"
            placeholder="Demand Amount for Rent or Sale"
            maxLength={9}
            onInput={(e) => {
              restrictInput(e, 9);
            }}
            onChange={(e) => {
              setPropertyDetails({
                ...propertyDetails,
                // DemandPrice: e.target.value,
                DemandPrice: e.target.value.trim(),
                // DemandPriceInWords: amountToWords(e.target.value)
              });
            }}
            value={propertyDetails && propertyDetails.DemandPrice}
          />
          <div style={{ fontSize: "smaller" }}>
            {convertToWords(propertyDetails.DemandPrice)}
          </div>
        </div>

        {propertyDetails && (propertyDetails.Purpose.toUpperCase() === 'AVAILABLE FOR RENT' || propertyDetails.Purpose.toUpperCase() === 'RENTED OUT') && <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">Maintenance fees</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              className="custom-input"
              style={{ width: "30%", paddingRight: "10px" }}
              type="number"
              placeholder="Optional"
              maxLength={6}
              onInput={(e) => {
                restrictInput(e, 6);
              }}
              onChange={(e) =>
                setPropertyDetails({
                  ...propertyDetails,
                  MaintenanceCharges: e.target.value.trim(),
                })
              }
              value={propertyDetails && propertyDetails.MaintenanceCharges}
            />
            <div
              style={{
                width: "70%",
                borderLeft: "2px solid #ddd",
                padding: "5px 0 5px 12px",
              }}
            >
              <div className="radio_group" style={{ gridColumnGap: "5px" }}>
                <div className="radio_group_single">
                  <div
                    className={
                      propertyDetails.MaintenanceChargesFrequency ===
                        "Monthly"
                        ? "custom_radio_button radiochecked"
                        : "custom_radio_button"
                    }
                  >
                    <input
                      type="checkbox"
                      id="maintenane_monthly"
                      onClick={(e) => {
                        setPropertyDetails({
                          ...propertyDetails,
                          MaintenanceChargesFrequency: "Monthly",
                        });
                      }}
                    />
                    <label
                      htmlFor="maintenane_monthly"
                      style={{ padding: "6px 0 10px 22px", height: "30px" }}
                    >
                      <div className="radio_icon">
                        <span
                          className="material-symbols-outlined add"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          add
                        </span>
                        <span
                          className="material-symbols-outlined check"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          done
                        </span>
                      </div>
                      <h6 style={{ fontSize: "0.8rem" }}>Monthly</h6>
                    </label>
                  </div>
                </div>
                <div className="radio_group_single">
                  <div
                    className={
                      propertyDetails.MaintenanceChargesFrequency ===
                        "Quarterly"
                        ? "custom_radio_button radiochecked"
                        : "custom_radio_button"
                    }
                  >
                    <input
                      type="checkbox"
                      id="maintenance_quarterly"
                      onClick={(e) => {
                        setPropertyDetails({
                          ...propertyDetails,
                          MaintenanceChargesFrequency: "Quarterly",
                        });
                      }}
                    />
                    <label
                      htmlFor="maintenance_quarterly"
                      style={{
                        padding: "6px 0 10px 22px",
                        height: "30px",
                        width: "90%",
                      }}
                    >
                      <div className="radio_icon">
                        <span
                          className="material-symbols-outlined add"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          add
                        </span>
                        <span
                          className="material-symbols-outlined check"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          done
                        </span>
                      </div>
                      <h6 style={{ fontSize: "0.8rem" }}>Quarterly</h6>
                    </label>
                  </div>
                </div>

                <div className="radio_group_single">
                  <div
                    className={
                      propertyDetails.MaintenanceChargesFrequency === "Yearly"
                        ? "custom_radio_button radiochecked"
                        : "custom_radio_button"
                    }
                  >
                    <input
                      type="checkbox"
                      id="maintenance_yearly"
                      onClick={(e) => {
                        setPropertyDetails({
                          ...propertyDetails,
                          MaintenanceChargesFrequency: "Yearly",
                        });
                      }}
                    />
                    <label
                      htmlFor="maintenance_yearly"
                      style={{
                        padding: "6px 0 10px 22px",
                        height: "30px",
                        width: "90%",
                      }}
                    >
                      <div className="radio_icon">
                        <span
                          className="material-symbols-outlined add"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          add
                        </span>
                        <span
                          className="material-symbols-outlined check"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          done
                        </span>
                      </div>
                      <h6 style={{ fontSize: "0.8rem" }}>Yearly</h6>
                    </label>
                  </div>
                </div>
                <div className="radio_group_single">
                  <div
                    className={
                      propertyDetails.MaintenanceChargesFrequency ===
                        "NA"
                        ? "custom_radio_button radiochecked"
                        : "custom_radio_button"
                    }
                  >
                    <input
                      type="checkbox"
                      id="maintenance_na"
                      onClick={(e) => {
                        setPropertyDetails({
                          ...propertyDetails,
                          MaintenanceChargesFrequency: "NA",
                        });
                      }}
                    />
                    <label
                      htmlFor="maintenance_na"
                      style={{ padding: "6px 0 10px 22px", height: "30px" }}
                    >
                      <div className="radio_icon">
                        <span
                          className="material-symbols-outlined add"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          add
                        </span>
                        <span
                          className="material-symbols-outlined check"
                          style={{
                            fontSize: "1.2rem",
                            transform: "translateX(-3px)",
                          }}
                        >
                          done
                        </span>
                      </div>
                      <h6 style={{ fontSize: "0.8rem" }}>NA</h6>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ fontSize: "smaller", borderTop: "1px solid #ddd" }}>
            {convertToWords(propertyDetails.MaintenanceCharges)}
          </div>
        </div>}

        <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">State</span>
          <Select
            className=""
            onChange={handleStateChange}
            options={statesOptionsSorted.current}
            value={state}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                outline: "none",
                background: "#efefef",
                border: "none",
                borderBottom: "none",
                paddingLeft: "10px",
                textTransform: "capitalize",
              }),
            }}
          />
        </div>

        <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">City</span>
          <Select
            className=""
            onChange={handleCityChange}
            options={citiesOptionsSorted.current}
            value={city}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                outline: "none",
                background: "#efefef",
                border: "none",
                borderBottom: "none",
                paddingLeft: "10px",
                textTransform: "capitalize",
              }),
            }}
          />
        </div>

        {/* <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">City</span>          
          <SearchBarAutoComplete
            enabled={state && state.value === "Select State" ? true : false}
            dataList={distinctValuesCity}
            placeholderText={"Search City"}
            getQuery={setSearchedCity}
            queryValue={propertyDetails ? propertyDetails.City : ""}
            setRedirectFlag={setRedirectFlag}
          ></SearchBarAutoComplete>
        </div> */}

        <div className={addLocalityDiv ? 'pop-up-change-number-div open' : 'pop-up-change-number-div'} >
          <div className="direct-div">
            <span onClick={closeAddLocalityDiv} className="material-symbols-outlined close-button">
              close
            </span>
            <h1 style={{ color: 'var(--theme-orange)' }}>Add New Locality</h1>
            <br />

            <div className="form_field">
              <input
                id="id_addNewLocality"
                type="text"
                placeholder="Add new locality name..."
                style={{ borderRadius: '8px', borderBottomColor: 'var(--theme-orange)' }}
              />
            </div>

            {/* new code */}
            <div className="change-number-button-div">
              <button className="mybutton button5" onClick={closeAddLocalityDiv} style={{ background: "#afafaf" }}>Back</button>
              <button className="mybutton button5" onClick={(e) => addNewLocality(e)}>Confirm</button>
            </div>
            {/* new code */}

          </div>
        </div>

        <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">Locality</span>
          <SearchBarAutoComplete
            enabled={
              propertyDetails && propertyDetails.City === "Select City" ? true : false
            }
            dataList={distinctValuesLocality}
            placeholderText={"Search Localities..."}
            getQuery={setSearchedLocality}
            queryValue={propertyDetails ? propertyDetails.Locality : ""}
            setRedirectFlag={setRedirectFlag}
          ></SearchBarAutoComplete>

          {!(city.value === "Select City" || city.value === 'undefined') && <button onClick={openAddLocalityDiv} className="mybutton button5" style={{ marginBottom: '0', height: '28px', fontSize: '0.8rem' }}>Add New Locality</button>}

        </div>

        <div className={addSocietyDiv ? 'pop-up-change-number-div open' : 'pop-up-change-number-div'} >
          <div className="direct-div">
            <span onClick={closeAddSocietyDiv} className="material-symbols-outlined close-button">
              close
            </span>
            <h1 style={{ color: 'var(--theme-orange)' }}>Add New Society</h1>
            <br />

            <div className="form_field">
              <input
                id="id_addNewSociety"
                type="text"
                placeholder="Add new society name..."
                style={{ borderRadius: '8px', borderBottomColor: 'var(--theme-orange)' }}
              />
            </div>

            {/* new code */}
            <div className="change-number-button-div">
              <button className="mybutton button5" onClick={closeAddSocietyDiv} style={{ background: "#afafaf" }}>Back</button>
              <button className="mybutton button5" onClick={(e) => addNewSociety(e)} >Confirm</button>
            </div>
            {/* new code */}

          </div>
        </div>
        <div className="form_field st-2 new_radio_groups_parent new_single_field n_select_bg">
          <span className="no-floating">Society</span>
          <SearchBarAutoComplete
            enabled={
              propertyDetails && propertyDetails.Locality === ""
                ? true
                : false
            }
            dataList={distinctValuesSociety}
            placeholderText={"Search Societies..."}
            getQuery={setSearchedSociety}
            queryValue={propertyDetails ? propertyDetails.Society : ""}
            setRedirectFlag={setRedirectFlag}
          ></SearchBarAutoComplete>
          {(propertyDetails && propertyDetails.Locality) && <button onClick={openAddSocietyDiv} className="mybutton button5" style={{ marginBottom: '0', height: '28px', fontSize: '0.8rem' }}>Add New Society</button>}
        </div>
      </div>
      <div className="">

        <div className="verticall_gap"></div>
        <div className="next_btn_back">
          {formError && <p className="error">{formError}</p>}
          {formWarning && <p className="warning">{formWarning}</p>}
          {formSuccess && <p className="success">{formSuccess}</p>}

          <div style={{ display: "flex", alignItems: "center" }} >

            <div className="" style={{ width: "100%", padding: '0 10px 0 0' }}>
              <button className="theme_btn full_width btn_fill" onClick={handleBackSubmit} >
                {"<< Back"}
              </button>
            </div>
            {propertyid !== "new" && <div className="" style={{ width: "100%", padding: '0 0 0 0' }}>
              <button
                className="theme_btn full_width btn_border"
                // onClick={handleSaveSubmit}
                onClick={(e) => handleSubmit(e, 'Save')}
              >
                Save
              </button>
            </div>}
            <div className="" style={{ width: "100%", padding: '0 0 0 10px' }}>
              <button
                className="theme_btn full_width btn_fill"
                onClick={(e) => handleSubmit(e, 'Next')}
              >
                {"Next >>"}
              </button>
            </div>
          </div></div>

      </div>
    </div >
  );
};

export default Stage1;
